import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import NewsBanner from '../components/NewsBanner'

import banciao1 from '../assets/images/IMG_7883.jpg'
import news2Pic1 from '../assets/images/news2-pic1.jpg'
import news3pic from '../assets/images/news3.jpg'
import news4pic from '../assets/images/news4-1.jpg'
import new5pic2 from '../assets/images/new5pic2.jpg'
import new6pic1 from '../assets/images/news6pic1.jpg'
import new7pic1 from '../assets/images/race.jpg'
import new8pic1 from '../assets/images/1003.jpg'
import new9pic1 from '../assets/images/news9-1.jpg'
import new10pic1 from '../assets/images/815.jpg'



const News = props => (
  <Layout>
    <Helmet>
      <title>健身資訊｜三健客3musclers健身俱樂部</title>
      <meta name="description" content="三健客3musclers是您專業的健身教練，協助您在體態、重訓、增肌、肌力訓練的需求，台北健身房分館也不定期會有優惠、健身資訊、學生方案等，讓您可以更輕鬆的加入健身的行列。" />
      <meta name="keywords" content="三健客,健身房,內湖健身房,板橋健身房,永和健身房,健身房推薦,健身俱樂部,健身房課程,私人健身房,教練課程,健身房教練,團體課程" />
    </Helmet>

    <NewsBanner />

    <div id="main">
      {/* <section id="one" style={{backgroundColor:'#d2aa0d'}}>
        <div className="inner">
          <header className="major">
            <h2>場館介紹</h2>
          </header>
          <p>
            目前三健客擁有三間分店，分別為內湖店、板橋店、永和店
            <br />
          </p>
        </div>
      </section> */}
      <section id="two" className="spotlights">
		<section>
          <Link to="/news-10" className="image">
            <img src={new10pic1} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>【 單次入場、會籍收費調整 】</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-10" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
		<section>
          <Link to="/news-9" className="image">
            <img src={new9pic1} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>【恢復營業，你準備好了嗎？】</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-9" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
		
		<section>
          <Link to="/news-8" className="image">
            <img src={new8pic1} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>【三健客與您一同積極抗疫，守護台灣】</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-8" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
		<section>
          <Link to="/news-7" className="image">
            <img src={new7pic1} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>【第一屆三健客人氣爭霸戰——正式開戰👑】</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-7" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <Link to="/news-6" className="image">
            <img src={new6pic1} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>配合秋冬疫情專案</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-6" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <Link to="/news-5" className="image">
            <img src={new5pic2} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>三健客三週年慶-好康三重送 🎉</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-5" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* <section>
          <Link to="/news-4" className="image">
            <img src={news4pic} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>永和店預售優惠方案</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-4" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
        {/* <section>
          <Link to="/news-3" className="image">
            <img src={news3pic} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>三健客板橋店暑期學生方案</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-3" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
        {/* <section>
          <Link to="/news-3" className="image">
            <img src={news3pic} alt="3musclers" className='image'/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>三健客板橋店暑期學生方案</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-3" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
        {/* <section style={{}}>
          <Link to="/news-2" className="image">
            <img src={news2Pic1} alt="3musclers" style={{height:'100%'}} />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>三健客永和店即將開幕</h3>
              </header>
              <ul className="actions">
                <li>
                  <Link to="/news-2" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
        <section style={{}}>
          <Link to="/news-1" className="image">
            <img src={banciao1} alt="3musclers" style={{objectFit:'cover'}}/>
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>因應肺炎疫情措施</h3>
              </header>
              {/* <p>
                三健客內湖店在2017年十月初開幕，內湖地區喜歡健身的朋友有福了!
              </p> */}
              <ul className="actions">
                <li>
                  <Link to="/news-1" className="button">
                  	more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* <section style={{}}>
          <Link to="/404" className="image">
            <img src={banciao2} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>永和店</h3>
              </header>
              <p>
                三健客內湖店在2017年十月初開幕，內湖地區喜歡健身的朋友有福了!
              </p>
              <ul className="actions">
                <li>
                  <Link to="/404" className="button">
                    more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section> */}
      </section>
    </div>
  </Layout>
)

export default News
