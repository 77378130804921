import React from 'react'

const NewsBanner = (props) => (
    <section id="banner" className="style2" style={{height:'600px'}}>
        <div className="inner">
            <header className="major">
                <h1>最新消息</h1>
            </header>
            <div className="content">
                <p>三健客3musclers是您專業的健身教練，協助您在體態、重訓、增肌、肌力訓練的需求，台北健身房分館也不定期會有優惠、健身資訊、學生方案等，讓您可以更輕鬆的加入健身的行列。
                </p>
            </div>
        </div>
    </section>
)

export default NewsBanner
